import React from 'react';
import PropTypes from 'prop-types';
//import { graphql, useStaticQuery } from 'gatsby'

const Footer = props => {
    // const data = useStaticQuery(graphql`
        //         query Copyright {
        //             wordpress {
        //                 mediaItem(id: "13", idType: DATABASE_ID) {
        //                     sourceUrl
        //                 }
        //             }
        //         }
        // `)
        return (
            <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
                <p className="copyright">&copy; COPYRIGHT 2019 ERAVILLE CONCEPT WEB</p>
            </footer>
            )
};

Footer.propTypes = {
    timeout: PropTypes.bool,
};

export default Footer;
