/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, keywords, title, twitterUsername }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        lang
                        keywords
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const titlePage = title || site.siteMetadata.title;
    const titlePageTemplate =
        `%s | ${site.siteMetadata.title}` || `${site.siteMetadata.title}`;
    const keyWordsArray = keywords || site.siteMetadata.keywords;
    const twitterUser = twitterUsername || site.siteMetadata.twitterUsername;
    const urlSite = `${site.siteMetadata.siteUrl}${'/'}`;

    return (
        <Helmet title={titlePage} titleTemplate={titlePageTemplate}>
            <html lang="fr" />
            <meta name="docsearch:version" content="2.0" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
            />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={keyWordsArray} />
            <meta name="google-site-verification" content="gWY8AOcDDFIMnKJ8zxXy1ivuKQuo9k4thhTj7PLhkTU" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="fr" />
            {urlSite && <meta property="og:url" content={urlSite} />}
            {titlePage && <meta property="og:title" content={titlePage} />}
            {metaDescription && (
                <meta property="og:description" content={metaDescription} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUser && (
                <meta name="twitter:creator" content={twitterUser} />
            )}
            {titlePage && <meta name="twitter:title" content={titlePage} />}
            {metaDescription && (
                <meta name="twitter:description" content={metaDescription} />
            )}
            )}
        </Helmet>
    );
}

Seo.defaultProps = {
    lang: `fr`,
    meta: [],
    keywords: '',
    description: ``,
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    pathname: PropTypes.string,
};

export default Seo;
