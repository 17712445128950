import React from 'react'
import PropTypes from 'prop-types'

import '../../assets/scss/main.scss'
import Seo from '../Seo/Seo'
import Footer from '../Footer/Footer'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Box } from '@material-ui/core'

const Layout = ({ children, location }) => {
    const data = useStaticQuery(graphql`
        query Background {
            file(relativePath: {eq: "ecw-bg.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            wordpress {
                mediaItem(id: "13", idType: DATABASE_ID) {
                    sourceUrl
                }
            }
        }
    `)

    if (location && location.pathname === '/') {
        return (
            <div>
                <Seo title={'ECW'} />
                {children}
                <Box id="bg">
                    <Img
                        fluid={data.file.childImageSharp.fluid}
                    />
                </Box>
                <Footer timeout={false} />
            </div>
        )
    } else {
        return (
            <div id="wrapper" className="page">
                <Seo title={'ECW'} />
                {children}
                <Box id="bg">
                    {' '}
                    <Img fluid={data.file.childImageSharp.fluid} />
                </Box>
                <Footer timeout={false} />
            </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
